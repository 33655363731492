import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLanguage } from "@config/i18n";
import Help from "@modules/Help";
export const getStaticProps: GetStaticProps = async ({
  locale
}) => ({
  props: {
    ...(await serverSideTranslations(locale || defaultLanguage, ["common", "components", "pages"]))
  },
  revalidate: 60
});
export default Help;