import { useCallback } from "react";
import { useRouter } from "next/router";
import blogFetchApi from "@api/blogApi";
import { useQuery } from "@tanstack/react-query";
import decodeHtmlCode from "@utils/decodeHtmlCode";
const useFaqs = () => {
  const router = useRouter();
  const locale = router.locale || "id";
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: ["faqs"],
    queryFn: () => blogFetchApi<FaqApi[]>({
      url: `/faq?_embed&faq_category=1912&order=asc&per_page=100&page=1`,
      method: "GET"
    })
  });
  const parseData = useCallback((data: FaqApi[], locale: string) => {
    let filteredData: FaqApi[];
    let newData: FaqModel[];
    if (locale === "en") {
      filteredData = data.filter(faq => faq.slug?.includes(`-en`) && faq.slug !== "fees-id" && faq.slug !== "fees-en");
    } else {
      filteredData = data.filter(faq => faq.slug?.includes(`-id`) && String(faq.slug).match(/^((?!-en).)*$/g) && faq.slug !== "fees-id" && faq.slug !== "fees-en");
    }
    newData = filteredData.map(faq => {
      const formatedTitle = decodeHtmlCode(faq.title.rendered);
      const replacedContent = String(faq.content.rendered).replace(/<a href="https:\/\/help\.rekeningku\.com\//g, `<a href="https://reku.id/help/`);
      const formatedContent = decodeHtmlCode(replacedContent);
      return {
        id: faq.id,
        title: formatedTitle,
        content: formatedContent,
        slug: faq.slug,
        categoryId: faq.faq_category[0],
        date: faq.date,
        date_gmt: faq.date_gmt,
        excerpt: faq.excerpt.rendered,
        yoast_head: faq.yoast_head
      };
    });
    return newData;
  }, [locale]);
  const faqs = data?.data ? parseData(data.data, locale) : null;
  return {
    faqs: faqs,
    isLoading: isLoading
  };
};
const useFaq = (slug: string) => {
  const {
    data
  } = useQuery({
    queryKey: ["faq", slug],
    queryFn: () => blogFetchApi<FaqApi[]>({
      url: `/faq?_embed&slug=${slug}`,
      method: "GET"
    })
  });
  const parseData = useCallback((data: FaqApi[]) => {
    let categories: number[] | undefined = data?.[0]?.faq_category.filter(category => category !== 11 && category !== 18 && category !== 1912);
    if (categories && categories?.length > 1) {
      categories = categories?.filter(category => category !== 12);
    }
    const replacedContent = String(data?.[0]?.content.rendered).replace(/<a href="https:\/\/help\.reku\.id\//g, `<a href="https://reku.id/help/`);
    const formatedTitle = decodeHtmlCode(data?.[0]?.title.rendered || "");
    const formatedContent = decodeHtmlCode(replacedContent);
    const faq: FaqModel = {
      id: data?.[0]?.id || 0,
      title: formatedTitle,
      content: formatedContent,
      slug: data?.[0]?.slug || "",
      categoryId: categories?.[0] || 0,
      date: data?.[0]?.date || "",
      date_gmt: data?.[0]?.date_gmt || "",
      excerpt: data?.[0]?.excerpt?.rendered || "",
      yoast_head: data?.[0]?.yoast_head || ""
    };
    if (!faq.id) return null;
    return faq;
  }, []);
  const faq = data?.data ? parseData(data.data) : null;
  return {
    faq,
    isLoading: !faq
  };
};
const useFaqsByCategory = (id: number | undefined) => {
  const router = useRouter();
  const locale = router.locale || "id";
  const slug = router.query.slug || "";
  const {
    data
  } = useQuery({
    queryKey: ["faq-category", id],
    queryFn: () => id ? blogFetchApi<FaqApi[]>({
      url: `/faq?_embed&faq_category=${id}&per_page=20&page=1`,
      method: "GET"
    }) : null
  });
  const parseData = useCallback((data: FaqApi[]) => {
    let filteredData: FaqApi[] | undefined;
    let newData: FaqModel[] | undefined;
    if (data) {
      if (locale === "en") {
        filteredData = data.filter(faq => faq.slug?.includes(`-en`));
      } else {
        filteredData = data.filter(faq => faq.slug?.includes(`-id`) && String(faq.slug).match(/^((?!-en).)*$/g));
      }
      newData = filteredData.map(faq => {
        const formatedTitle = decodeHtmlCode(faq.title.rendered);
        const formatedContent = decodeHtmlCode(faq.content.rendered);
        return {
          id: faq.id,
          title: formatedTitle,
          content: formatedContent,
          slug: faq.slug,
          categoryId: faq.faq_category[0],
          date: faq.date,
          date_gmt: faq.date_gmt,
          excerpt: faq.excerpt?.rendered,
          yoast_head: faq.yoast_head
        };
      }).filter(faq => faq.slug !== slug);
      return newData;
    }
  }, [locale, slug, id]);
  const faqCategories = data?.data ? parseData(data.data) : null;
  return {
    faqCategories,
    isLoading: !faqCategories
  };
};
export { useFaq, useFaqsByCategory };
export default useFaqs;