import Head from "next/head";
import { useTranslation } from "next-i18next";
import { Else, If, Then } from "@components/If";
import Link from "@components/Link";
import Skeleton from "@components/Skeleton";
import { Card, Icons } from "@reku-web/ui";
import useFaqs from "../hooks/useFaq";
const Faqs = () => {
  const {
    t
  } = useTranslation("pages");
  const {
    faqs,
    isLoading
  } = useFaqs();
  const faqPage = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs?.map(({
      title,
      content
    }) => ({
      "@type": "Question",
      name: title,
      acceptedAnswer: {
        "@type": "Answer",
        text: content
      }
    }))
  };
  const renderedLoading = [...new Array(10)].map((_, index) => <Skeleton key={index} width='100%' height={54} />);
  const renderedLists = faqs?.map(({
    title,
    slug
  }) => <Link key={slug as string} href={slug === "fees-id" || slug === "fees-en" ? `/fees` : `/help/${slug}`}>
            <Card cardClassName='flex items-center justify-between gap-3 py-6 xl:py-3 xl:px-6'>
                <p className='text-heading-xs xl:text-lg font-semibold'>{title}</p>
                <Icons icon='ChevronRight' className='w-5 h-5 xl:w-6 xl:h-6' />
            </Card>
        </Link>);
  return <div className='mt-10 xl:mt-[64px] reku-new-theme'>
            <Head>
                <script type='application/ld+json' dangerouslySetInnerHTML={{
        __html: JSON.stringify(faqPage)
      }} />
            </Head>
            <h2 className='text-center text-heading-md xl:text-heading-lg text-text-neutral-strong dark:text-dark-text-neutral-strong'>
                {t("faq.title")}
            </h2>
            <div className='mt-3 xl:mt-8 grid grid-cols-1 xl:grid-cols-2 gap-y-3 gap-x-9'>
                <If condition={isLoading}>
                    <Then>{renderedLoading}</Then>
                    <Else>{renderedLists}</Else>
                </If>
            </div>
        </div>;
};
export default Faqs;